export const formatDate = ts => {
  const date = new Date(ts);
  return new Intl.DateTimeFormat("en-US").format(date);
};

export const queryFixer = (origquery, _) => {
  return origquery;
};
const numDaysBetween = val => {
  const d1 = new Date(val);
  const d2 = new Date();
  const diff = Math.abs(d1.getTime() - d2.getTime());
  return diff / (1000 * 60 * 60 * 24);
};
export const getObjectData = (data, contentType, isJackpots = false) => {
  contentType = contentType === "SlotsGameReview" ? "BrandReview" : "SlotsGameReview";
  return data.map(el => {
    const taxonomy = _.omit(el, [
      "Title",
      "Meta Title",
      "Event",
      "Logo",
      "Download Button",
      "Popularity",
      "uri",
      "nid",
    ]);
    _.forOwn(taxonomy, function (value, key) {
      if (["Start", "End", "Date"].includes(key)) {
        if (["Start"].includes(key) && numDaysBetween(formatDate(value)) > 30) delete taxonomy[key];
        else taxonomy[key] = formatDate(value);
      }
    });
    return {
      Title: el?.Title || el?.Event || el?.["Meta Title"],
      Logo: isJackpots && el.nid ? { nid: el.nid, contentType } : el?.Logo,
      uri: el?.uri,
      Popularity: ref(el?.Popularity),
      "Download Button": el["Download Button"],
      taxonomy,
    };
  });
};

export const formatTaxonomy = taxonomy =>
  _.reduce(
    taxonomy,
    (res, val, key) => {
      res[key] = _.isPlainObject(val) ? formatTaxonomy(val) : val;
      return res;
    },
    {}
  );

export const routeFix = route => {
  route = route.replace(/^[/]+|[/]+$/g, "");
  if (!route || route === "") route = "index.html";
  return decodeURIComponent(route);
};

export const isUzi = type => _.startsWith(type, "Uzi");
